<template>
    <v-container>
        <v-layout justify-center align-center>
            <v-flex xs12>
                <v-card class="mt-12 pa-4">
                    <v-card-title class="headline">Acceso denegado</v-card-title>
                    <v-card-text>
                        <p> No tienes permiso para acceder a esta página.</p>
                        <p> Favor de contactar al administrador para obtener ayuda.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="redirectToHome">Regresar a la página principal</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'Unauthorized',
    methods: {
        redirectToHome() {
            this.$router.push('/');
        }
    }
}
</script>